import { getLocalTime } from "../DateStuff"
const dateTimeRenderer = props => getLocalTime(props.value)
const anchorTextRenderer = props => <a
    href={`/student/${props.value}`}
    style={{ color: props.color, fontWeight: 'bolder' }}
>Student-{props.value}</a>


export const updateLogsColumnDefs = [
    {
        field: "update_log_id",
        headerName: "Log ID"
    },
    {
        field: "updater.full_name",
        headerName: "Updater"
    },
    {
        field: "status.status_name",
        headerName: "Updated Status To"
    },
    {
        field: "student.name",
        headerName: "For Student"
    },
    {
        field: "student.student_id",
        headerName: "Student Info",
        cellRenderer: anchorTextRenderer,
        cellRendererParams: {
            color: '#3960e9'
        }
    },
    {
        field: "created_at",
        headerName: "Update At",
        cellRenderer: dateTimeRenderer
    }
]
