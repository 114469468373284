const anchorTextRenderer = props => <a
    href={`/filter-by-gender/${props.value}`}
    style={{ color: props.color, fontWeight: 'bolder' }}
>Gender-{props.value.split('?name=')[0]}</a>



function urlMaker(params) {
    return params.data.gender_id + "?name=" + params.data.gender_name;
  }

export const genderColumnDefs = [
    {
        field: "gender_id",
        headerName: "Gender ID",
        cellRenderer: anchorTextRenderer,
        cellRendererParams: {
            color: '#3960e9'
        },
        valueGetter: urlMaker
    },
    { 
        field: "gender_name",
        headerName: "Gender Name"
    },
    {
        field: "present_count",
        headerName: "Present Count"
    },
    {
        field: "absent_count",
        headerName: "Absent Count"
    },
    {
        field: "not_fill_count",
        headerName: "Not Fill Count"
    },
    {
        field: "total_count",
        headerName: "Total Count"
    }
]
