const anchorTextRenderer = props => <a
    href={`/filter-by-college/${props.value}`}
    style={{ color: props.color, fontWeight: 'bolder' }}
>College-{props.value.split('?name=')[0]}</a>


function urlMaker(params) {
    return params.data.college_id + "?name=" + params.data.college_name;
}

export const collegeColumnDefs = [
    {
        field: "college_id",
        headerName: "College ID",
        cellRenderer: anchorTextRenderer,
        cellRendererParams: {
            color: '#3960e9'
        },
        valueGetter: urlMaker
    },
    {
        field: "college_name",
        headerName: "College Name"
    },
    {
        field: "present_count",
        headerName: "Present Count"
    },
    {
        field: "absent_count",
        headerName: "Absent Count"
    },
    {
        field: "not_fill_count",
        headerName: "Not Fill Count"
    },
    {
        field: "total_count",
        headerName: "Total Count"
    }
]
