import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';

import AllStudents from './pages/AllStudents';
import Student from './pages/Student';

import UpdateLogs from './pages/UpdateLogs';

import FilterGenders from './pages/Admin/Filter/Gender/Genders';
import ViewGender from './pages/Admin/Filter/Gender/ViewGender';

import FilterStatuses from './pages/Admin/Filter/Status/Statuses';
import ViewStatus from './pages/Admin/Filter/Status/ViewStatus';

import FilterColleges from './pages/Admin/Filter/College/Colleges';
import ViewCollege from './pages/Admin/Filter/College/ViewCollege';

function App() {
  return (
    <Routes>
      <Route exact path = "/" element = { <Login /> }></Route>
      <Route exact path = "/all-students" element = { <AllStudents /> }></Route>
      
      <Route exact path = "/student/:id" element = { <Student /> }></Route>
      <Route exact path = "/update-logs" element = { <UpdateLogs /> }></Route>

      <Route exact path = "/filter-by-gender/display-all" element = { <FilterGenders /> }></Route>
      <Route exact path = "/filter-by-gender/:id" element = { <ViewGender /> }></Route>

      <Route exact path = "/filter-by-status/display-all" element = { <FilterStatuses /> }></Route>
      <Route exact path = "/filter-by-status/:id" element = { <ViewStatus /> }></Route>

      <Route exact path = "/filter-by-college/display-all" element = { <FilterColleges /> }></Route>
      <Route exact path = "/filter-by-college/:id" element = { <ViewCollege /> }></Route>
    </Routes>
  );
}

export default App;
