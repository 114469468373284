const anchorTextRenderer = props => <a
    href={`/student/${props.value}`}
    style={{ color: props.color, fontWeight: 'bolder' }}
>Fresher-{props.value}</a>

export const studentsColumnFullDefs = [
    {
        field: "student_id",
        headerName: "Student ID",
        cellRenderer: anchorTextRenderer,
        cellRendererParams: {
            color: '#3960e9'
        }
    },
    { field: "name" },
    {
        field: "email",
        headerName: "Email"
    },
    {
        field: "contact_number",
        headerName: "Contact Number"
    },
    {
        field: "stream.stream_name",
        headerName: "Stream"
    },
    {
        field: "college.college_name",
        headerName: "College"
    },
    {
        field: "department.department_name",
        headerName: "Department"
    },
    {
        field: "gender.gender_name",
        headerName: "Gender"
    },
    {
        field: "status.status_name",
        headerName: "Status"
    },
    {
        field: "comment",
        headerName: "Comments"
    },
]
