import Navbar from "../Components/Nav";
import { IsLoggedIn, jwtHeader } from "../utils/AuthManager";
import {
    Button,
    Text,
} from '@chakra-ui/react';

import { useCallback, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; // Optional theme CSS

import axios from "axios";

import { updateLogsColumnDefs } from "../utils/data/UpdateLog";
import { defaultColDefinition } from "../utils/data/DefaultColumn";

import ChangePageTitle from "../Components/ChangePageTitle";

export default function UpdateLogs() {
    const gridRef = useRef();

    const [columnDefs] = useState(updateLogsColumnDefs);
    const [rowData, setRowData] = useState([]);

    const containerStyle = { width: '100%', height: '100%' };
    const gridStyle = { height: 600, width: '100%' };

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "All_UpdateLog_Data.csv" });
    }, []);

    const onGridReady = useCallback((params) => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/update-log/all`,
            headers: jwtHeader,
        }).then((response) => {
            setRowData(response.data);
        });
    }, []);

    return (
        <>
            <IsLoggedIn />
            <ChangePageTitle pageTitle="Freshers Day Admin Panel | All Update Logs"></ChangePageTitle>
            <Navbar>
                <Text fontSize={'3xl'} fontWeight={'bold'} color={'#323232'} my='5'>All Logs</Text>

                <Button
                    type={"submit"}
                    bg={'green.400'}
                    color={'white'}
                    mb={10}
                    onClick={onBtnExport}
                    _hover={{
                        bg: 'green.500',
                    }}>
                    Export as CSV
                </Button>

                <div style={containerStyle}>
                    <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDefinition}
                            suppressExcelExport={true}
                            onGridReady={onGridReady}
                        ></AgGridReact>
                    </div>
                </div>
            </Navbar>
        </>
    );
}
