const anchorTextRenderer = props => <a
    href={`/filter-by-status/${props.value}`}
    style={{ color: props.color, fontWeight: 'bolder' }}
>Status-{props.value.split('?name=')[0]}</a>



function urlMaker(params) {
    return params.data.status_id + "?name=" + params.data.status_name;
  }

export const statusColumnDefs = [
    {
        field: "status_id",
        headerName: "Status ID",
        cellRenderer: anchorTextRenderer,
        cellRendererParams: {
            color: '#3960e9'
        },
        valueGetter: urlMaker
    },
    { 
        field: "status_name",
        headerName: "Status Name"
    },
    {
        field: "count_eh",
        headerName: "Present Count"
    }
]
