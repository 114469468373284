import axios from "axios";
import {
    Text
} from '@chakra-ui/react';

import { useCallback, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; // Optional theme CSS

import Navbar from "../../../../Components/Nav";
import { IsLoggedIn, jwtHeader } from "../../../../utils/AuthManager";
import ChangePageTitle from "../../../../Components/ChangePageTitle";

import { statusColumnDefs } from "./StatusColumns";
import { defaultColDefinition } from "../../../../utils/data/DefaultColumn";

export default function FilterStatuses() {

    const gridRef = useRef();

    const [columnDefs] = useState(statusColumnDefs);
    const [rowData, setRowData] = useState([]);

    const containerStyle = { width: '100%', height: '100%' };
    const gridStyle = { height: 600, width: '100%' };

    const onGridReady = useCallback((params) => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/count/status-wise`,
            headers: jwtHeader,
        }).then((response) => {
            setRowData(response.data);
        });
    }, []);

    return (
        <>
            <IsLoggedIn />
            <ChangePageTitle pageTitle="Freshers Day Admin Panel | Count - Statuses"></ChangePageTitle>
            <Navbar>

                <Text fontSize={'3xl'} fontWeight={'bold'} color={'#323232'} my='10'>Students - Present Absent</Text>
                
                <div style={containerStyle}>
                    <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDefinition}
                            suppressExcelExport={true}
                            onGridReady={onGridReady}
                        ></AgGridReact>
                    </div>
                </div>

            </Navbar>
        </>
    );
}
