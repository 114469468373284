import { useEffect } from "react";
import { deleteCookie, getCookie } from "./CookieManager";

export function IsLoggedIn() {
    useEffect(
        () => {
            const jwt = getCookie('s5_admin_jwt');
            if (jwt === null) {
                window.location = "/";
            }
        },
        []
    );
    return (<></>);
}

export function Logout() {
    deleteCookie('s5_admin_jwt');
    window.location = "/";
}

export const jwtHeader = {
    "accept": "application/json",
    "Authorization": `Bearer ${getCookie('s5_admin_jwt')}`
}
