import Navbar from "../Components/Nav";
import { IsLoggedIn, jwtHeader } from "../utils/AuthManager";
import {
    Text,
    SimpleGrid,
    Input,
    Select,
    Button,
} from '@chakra-ui/react';

import { createRef, useEffect, useState } from "react";
import axios from "axios";
import { getLocalTime } from "../utils/DateStuff";
import ChangePageTitle from "../Components/ChangePageTitle";

export default function Student() {

    const [register, setRegister] = useState({});
    const [listOfStatuses, setlistOfStatuses] = useState([]);
    const [listOfGenders, setlistOfGenders] = useState([]);
    const [listOfColleges, setlistOfColleges] = useState([]);
    const [listOfParentStatuses, setlistOfParentStatuses] = useState([]);
    const [listOfStreams, setListOfStreams] = useState([]);

    useEffect(() => {
        // Getting list of statuses
        axios({
            method: "get",
            url: `${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/student/page`
        }).then(
            (response) => {
                setlistOfStatuses(response.data.statuses);
                setlistOfGenders(response.data.genders);
                setlistOfColleges(response.data.colleges);
                setlistOfParentStatuses(response.data.parent_statuses);
                setListOfStreams(response.data.streams)
            }
        );

        // Getting registration info
        axios({
            method: "get",
            url: `${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/student/info/${window.location.pathname.slice(9)}`,
            headers: jwtHeader,
        }).then(
            (response) => {
                setRegister(response.data);
            }).catch(
                (err) => {
                    console.log(err);
                }
            );
    }, []);


    const studentName = createRef();
    const departmentName = createRef();
    const genderValue = createRef();
    const collegeName = createRef();

    const commentSection = createRef();
    const statusCode = createRef();
    const parentStatusCode = createRef();
    const streamValue = createRef()

    // request params to be changed (comment, status id)
    function updateData(e) {
        e.preventDefault();
        console.log(commentSection.current.value);
        console.log(statusCode.current.value);
        console.log(window.location.pathname.slice(9))
        axios({
            method: "put",
            url: `${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/student/update/${window.location.pathname.slice(9)}`,
            headers: jwtHeader,
            data: {
                "name" : studentName.current.value,
                "gender_id": genderValue.current.value,
                "department": departmentName.current.value,
                "college_id" : collegeName.current.value,
                "stream_id": streamValue.current.value,
                "status_id": statusCode.current.value,
                "parent_status_id": parentStatusCode.current.value,
                "comment": commentSection.current.value,
            },
        }).then((response) => {
            console.log(response.data);
            alert("Updated");
        }).catch(
            (err) => {
                alert(err);
            }
        );
    }


    return (
        <>
            <IsLoggedIn />
            <ChangePageTitle pageTitle={"Freshers Desk Admin Panel | Student Info " + window.location.pathname.slice(9)}></ChangePageTitle>
            <Navbar>
                <Text fontSize={'3xl'} fontWeight={'bold'} color={'#323232'} my='5'>ID: {window.location.pathname.slice(9)}</Text>
                <form>
                    {register &&
                        <>
                            <SimpleGrid columns={{ base: 1, sm: 3, md: 3 }} spacing={{ base: 5, lg: 8 }}>
                                <div>
                                    <Text fontSize={'xl'} fontWeight={'bold'}>Name</Text>
                                    <Input colorScheme={'green'} bg={'#FFF'} ref={studentName} fontSize={'xl'} my={2} defaultValue={register.name}></Input>
                                    <Text fontSize={'xl'} my={2}>DbValue: {register.name}</Text>
                                </div>
                                <div>
                                    <Text fontSize={'xl'} fontWeight={'bold'}>College</Text>
                                    <Select ref={collegeName} bg={'#FFF'} key={register?.college} defaultValue={register?.college?.college_id}>
                                        <option value="" disabled>Select Option</option>
                                        {
                                            listOfColleges.map(
                                                (collegeE) => <option value={collegeE?.college_id} key={collegeE?.college_id}>{collegeE?.college_name}</option>
                                            )
                                        }
                                    </Select>
                                    <Text fontSize={'xl'} my={2}>DbValue: {register.college?.college_name}</Text>
                                </div>
                                <div>
                                    <Text fontSize={'xl'} fontWeight={'bold'}>Gender</Text>
                                    <Select ref={genderValue} bg={'#FFF'} key={register?.gender?.gender_id} defaultValue={register?.gender?.gender_id}>
                                        <option value="" disabled>Select Option</option>
                                        {
                                            listOfGenders.map(
                                                (genderr) => <option value={genderr?.gender_id} key={genderr?.gender_id}>{genderr?.gender_name}</option>
                                            )
                                        }
                                    </Select>
                                    <Text fontSize={'xl'} my={2}>DbValue: {register.gender?.gender_name}</Text>
                                </div>
                                
                                <div>
                                    <Text fontSize={'xl'} fontWeight={'bold'}>Department</Text>
                                    <Input colorScheme={'green'} bg={'#FFF'} ref={departmentName} fontSize={'xl'} my={2} defaultValue={register.department}></Input>
                                    <Text fontSize={'xl'} my={2}>DbValue: {register.department}</Text>
                                </div>

                                <div>
                                    <Text fontSize={'xl'} fontWeight={'bold'}>Status</Text>
                                    <Select ref={statusCode} bg={'#FFF'} key={register?.status?.status_id} defaultValue={register?.status?.status_id}>
                                        <option value="" disabled>Select Option</option>
                                        {
                                            listOfStatuses.map(
                                                (status) => <option value={status?.status_id} key={status?.status_id}>{status?.status_name}</option>
                                            )
                                        }
                                    </Select>
                                    <Text fontSize={'xl'} my={2}>DbValue: {register.status?.status_name}</Text>
                                </div>

                                <div>
                                    <Text fontSize={'xl'} fontWeight={'bold'}>Parent Came to Visit</Text>
                                    <Select ref={parentStatusCode} bg={'#FFF'} key={register?.parent_status?.status_id} defaultValue={register?.parent_status?.status_id}>
                                        <option value="" disabled>Select Option</option>
                                        {
                                            listOfParentStatuses.map(
                                                (status) => <option value={status?.status_id} key={status?.status_id}>{status?.status_name}</option>
                                            )
                                        }
                                    </Select>
                                    <Text fontSize={'xl'} my={2}>DbValue: {register.parent_status?.status_name}</Text>
                                </div>

                                <div>
                                    <Text fontSize={'xl'} fontWeight={'bold'}>Stream</Text>
                                    <Select ref={streamValue} bg={'#FFF'} key={register?.stream?.stream_id} defaultValue={register?.stream?.stream_id}>
                                        <option value="" disabled>Select Option</option>
                                        {
                                            listOfStreams.map(
                                                (stream) => <option value={stream?.stream_id} key={stream?.stream_id}>{stream?.stream_name}</option>
                                            )
                                        }
                                    </Select>
                                    <Text fontSize={'xl'} my={2}>DbValue: {register.stream?.stream_name}</Text>
                                </div>

                                <div>
                                    <Text fontSize={'xl'} fontWeight={'bold'}>Comments</Text>
                                    <Input colorScheme={'green'} bg={'#FFF'} ref={commentSection} fontSize={'xl'} my={2} defaultValue={register?.comment}></Input>
                                    <Text fontSize={'xl'} my={2}>DbValue: {register?.comment}</Text>
                                </div>
                                <div>
                                    <Text fontSize={'xl'} fontWeight={'bold'}>Last Modified at</Text>
                                    <Text fontSize={'xl'} my={2}>{getLocalTime(register.updated_at)}</Text>
                                </div>
                                <div>
                                    <Text fontSize={'xl'} fontWeight={'bold'}>Last Modified by</Text>
                                    <Text fontSize={'xl'} my={2}>{register.updater?.full_name}</Text>
                                </div>
                            </SimpleGrid>
                            <Button
                                onClick={updateData}
                                type={"submit"}
                                bg={'green.400'}
                                color={'white'}
                                my={4}
                                _hover={{
                                    bg: 'green.500',
                                }}>
                                Update
                            </Button>
                        </>
                    }
                </form>
            </Navbar>
        </>
    );
}
