import { getLocalTime } from "../DateStuff"
const dateTimeRenderer = props => getLocalTime(props.value)

const anchorTextRenderer = props => <a
    href={`/student/${props.value}`}
    style={{ color: props.color, fontWeight: 'bolder' }}
>Fresher-{props.value}</a>

export const studentsColumnDefs = [
    {
        field: "student_id",
        headerName: "Student ID",
        cellRenderer: anchorTextRenderer,
        cellRendererParams: {
            color: '#3960e9'
        }
    },
    { field: "name" },
    {
        field: "gender.gender_name",
        headerName: "Gender"
    },
    {
        field: "contact_number",
        headerName: "Contact Number"
    },
    {
        field: "college.college_name",
        headerName: "College"
    },
    {
        field: "department",
        headerName: "Department"
    },
    {
        field: "stream.stream_name",
        headerName: "Stream"
    },
    {
        field: "status.status_name",
        headerName: "Status"
    },
    {
        field: "parent_status.status_name",
        headerName: "Parent Attendance"
    },
    {
        field: "yoj",
        headerName: "Year of Joining"
    },
    {
        field: "yoc",
        headerName: "Year of Completion"
    },
    {
        field: "updater.full_name",
        headerName: "Updated By"
    },
    {
        field: "updated_at",
        headerName: "Updated At",
        cellRenderer: dateTimeRenderer
    },
    {
        field: "comment",
        headerName: "Comments"
    }
]
