import axios from "axios";
import {
    Text,
    Button
} from '@chakra-ui/react';

import { useCallback, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; // Optional theme CSS

import Navbar from "../../../../Components/Nav";
import { IsLoggedIn, jwtHeader } from "../../../../utils/AuthManager";
import ChangePageTitle from "../../../../Components/ChangePageTitle";

import { collegeColumnDefs } from "./CollegeColumns";
import { defaultColDefinition } from "../../../../utils/data/DefaultColumn";

export default function FilterColleges() {

    const gridRef = useRef();

    const [columnDefs] = useState(collegeColumnDefs);
    const [rowData, setRowData] = useState([]);

    const containerStyle = { width: '100%', height: '100%' };
    const gridStyle = { height: 600, width: '100%' };

    const genderName = decodeURIComponent(window.location.href).split('name=')[1]

    const onBtnExport = useCallback(() => {
        const date_str = new Date().toDateString().split(' ').join('_');

        gridRef.current.api.exportDataAsCsv(
            {
                fileName: `${genderName}_Registrations_${date_str}.csv`
            }
        );
    }, [genderName]);

    const onGridReady = useCallback((params) => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/count/college-all`,
            headers: jwtHeader,
        }).then((response) => {
            setRowData(response.data);
        });
    }, []);

    return (
        <>
            <IsLoggedIn />
            <ChangePageTitle pageTitle="Freshers Day Admin Panel | Count - Colleges"></ChangePageTitle>
            <Navbar>

                <Text fontSize={'3xl'} fontWeight={'bold'} color={'#323232'} my='10'>Filter by College</Text>

                <Button
                    type={"submit"}
                    bg={'blue.400'}
                    color={'white'}
                    mb={10}
                    onClick={onBtnExport}
                    _hover={{
                        bg: 'blue.500',
                    }}>
                    Export as CSV
                </Button>

                <div style={containerStyle}>
                    <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDefinition}
                            suppressExcelExport={true}
                            onGridReady={onGridReady}
                        ></AgGridReact>
                    </div>
                </div>

            </Navbar>
        </>
    );
}
